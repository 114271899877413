import { BoxForm } from '../../BoxForm';
import joinhaImg from '../../../assets/Feedback.svg';

import { Container, Title, Text, Image } from '../../../global';

interface NotFoundProps {
  readonly title?: string;
  readonly text?: string;
  readonly textButton: string;
  readonly buttonRedirect: string;
}

export function NotFound( {title, text, textButton, buttonRedirect}: NotFoundProps) {

  function handleRedirect(){
    localStorage.removeItem('redirecturl');
    localStorage.removeItem('pre-account');
    localStorage.removeItem('clientid');
    localStorage.removeItem('userid');
    localStorage.removeItem('qualificacao');
    localStorage.removeItem('token');
    window.location.href = buttonRedirect
  }
  
  return (
    <BoxForm heading="" buttonRightClick={() => handleRedirect()} buttonRight={textButton}>
      <Container>
        <Image src={joinhaImg} alt="" />
        <Title>
          {title}
        </Title>
        <Text>
          {text}
        </Text>
      </Container>
    </BoxForm>
  );
}
