import { useLocation } from 'react-router-dom';
import { CircularLoading } from '@vr/ds-react';
import { useEffect, useState } from 'react';
import { Container } from './styles';
import { TokenForm } from '../../components/FormsScreen/TokenForm';
import { ReadonlyForm } from '../../components/FormsScreen/ReadonlyForm';
import { FormEdit } from '../../components/FormsScreen/FormEdit';
import { Conclusion } from '../../components/FormsScreen/Conclusion';
import { SelectOptIn } from '../../components/FormsScreen/SelectOptIn';
import { NotFound } from '../../components/FormsScreen/NotFound';
import { useAuthToken } from '../../hooks/useAuthToken';
import { handleGetUserInfo } from '../../services/userService';
import apiService from '../../services/api';
import { PasswordForm } from '../../components/FormsScreen/PasswordForm';
import {getUserInfo} from '../../services/getUserInfo';

export default function ReRegistration() {
  const location = useLocation();
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const [isLoading, setIsLoading] = useState(true);
  const [stepSwitch, setStepSwitch] = useState(99);
  const [start, setStart] = useState(true);
  const token = useAuthToken();
  const redirecturl = urlParams.get('redirect_uri') ?? '';

  async function postQualification(id: string) {
    setIsLoading(true);
    apiService.post('/acesso-vr/v1/qualificacoes', {
      preConta: {
        idPreConta: id
      },
      aplicacaoOrigem: {
        idAplicacao: urlParams.get('client_id'),
        urlRedirecionamento: urlParams.get('redirect_uri')
      },
      processo: 'recadastramento'
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        client_id: process.env.REACT_APP_SENSEDIA_CLIENT_ID
      },
    }).then((res) => {
      if (token) {
        handleGetUserInfo(urlParams, token, setStepSwitch, setIsLoading, res.data.idQualificacao);
      }
    }).catch((err) => {
      const inputString = err.response.data.mensagens[0].descricao;
      const regex = /ID: ([a-f0-9-]{36})/;
      const match = inputString.match(regex);

      if (match) {
        const id = match[1];
        if (token) {
          handleGetUserInfo(urlParams, token, setStepSwitch, setIsLoading, id);
        }
      } else if (err.response.data.mensagens[0].descricao === 'O e-mail enviado já existe. Necessário troca de titularidade, foi enviado um código MFA para o e-mail solicitado.') {
        if (token) {
          handleGetUserInfo(urlParams, token, setStepSwitch, setIsLoading, id);
        }
      } else {
        setIsLoading(false);
        setStepSwitch(0);
      }

    })
  };

  useEffect(() => {
    localStorage.setItem('redirecturl', urlParams.get('redirect_uri') ?? '');
    localStorage.setItem('clientid', urlParams.get('client_id') ?? '');
    localStorage.setItem('userid', urlParams.get('UUID') ?? '');
    if (token && start) {
      getUserInfo().then(userInfo => {
        if(userInfo?.name) {
          setIsLoading(false);
          setStepSwitch(401);
          return;
        }

        postQualification(urlParams.get('UUID') ?? '');
        setStart(false);

      }).catch(error => {
        setIsLoading(false);
        setStepSwitch(401);
      });
    }
  }, [token]);

  return (
    <Container>
      <CircularLoading isLoading={isLoading} />
      {stepSwitch === 0 && <NotFound title="Não foi possível localizar seu cadastro" text='Entre em contato com a central de ajuda ou retorne a plataforma de origem.' textButton='Voltar' buttonRedirect={redirecturl} />}
      {stepSwitch === 401 && <NotFound title="Oops! Parece que você não tem permissão para acessar esta página" text='Entre em contato com a central de ajuda ou retorne a plataforma de origem.' textButton='Voltar' buttonRedirect={redirecturl} />}
      {stepSwitch === 1 && <SelectOptIn changeStep={setStepSwitch} changeLoading={setIsLoading} />}
      {stepSwitch === 2 && <TokenForm changeStep={setStepSwitch} changeLoading={setIsLoading} thisStep={stepSwitch} />}
      {stepSwitch === 3 && <FormEdit changeLoading={setIsLoading} changeStep={setStepSwitch} />}
      {stepSwitch === 4 && <ReadonlyForm changeStep={setStepSwitch} />}
      {stepSwitch === 5 && <PasswordForm changeStep={setStepSwitch} changeLoading={setIsLoading} />}
      {stepSwitch === 7 && <Conclusion />}
      {stepSwitch === 9 && <ReadonlyForm prev changeStep={setStepSwitch} />}
      {stepSwitch === 11 && <NotFound title="Já existe uma edição em andamento" text='Entre em contato com a central de ajuda ou retorne a plataforma de origem.' textButton='Voltar' buttonRedirect={redirecturl} />}
      {stepSwitch === 22 && <FormEdit changeLoading={setIsLoading} changeStep={setStepSwitch} conflictEmail />}
      {stepSwitch === 33 && <FormEdit changeLoading={setIsLoading} changeStep={setStepSwitch} conflictPhone />}
      {stepSwitch === 44 && <FormEdit changeLoading={setIsLoading} changeStep={setStepSwitch} conflictCpfprop />}
    </Container>
  );
}
