import { styled } from '@stitches/react';
import React, { useRef, useState, useEffect } from 'react';
import { Countdown } from '../Countdown';

interface InputTokenProps {
  readonly error?: boolean;
  readonly disabled?: boolean;
  readonly onChange: (code: string) => void;
  readonly label?: string;
  readonly resendFunction: () => void;
}

export function InputToken({ error, onChange, disabled, label, resendFunction }: InputTokenProps) {
  const Container = styled('div', {});

  const Content = styled('div', {
    width: '300px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    marginBottom: '10px',
  });

  const Input = styled('input', {
    width: '46px',
    height: '46px',
    borderRadius: '8px',
    border: '1px solid black',
    fontSize: '30px',
    textAlign: 'center',
    fontWeight: 'bold',
    outline: 'none',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&[type="number"]': {
      '-moz-appearance': 'textfield',
    },
    variants: {
      error: {
        true: {
          border: '1px solid #BF0000',
        },
      },
      disable: {
        true: {
          cursor: 'default',
          borderColor: '#A0AAB4',
        },
      },
    },
  });

  const Label = styled('label', {
    fontWeight: '600',
    fontSize: '12px',
  })

  const HitError = styled('p', {
    color: 'red',
    fontSize: '12px',
    fontWeight: '600',
    textAlign: 'left',
    width: '100%',
    position: 'absolute',
    bottom: '-15px',
  });

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [values, setValues] = useState<string[]>(Array(6).fill(''));

  const handleInput =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;

      if (/^\d$/.test(newValue) || newValue === '') {
        const newValues = [...values];
        newValues[index] = newValue;
        setValues(newValues);
        onChange(newValues.join(''));

        if (newValue !== '' && index < inputRefs.current.length - 1) {
          setTimeout(() => {
            inputRefs.current[index + 1]?.focus();
          }, 0);
        }

        if (newValue === '' && index > 0) {
          setTimeout(() => {
            inputRefs.current[index - 1]?.focus();
          }, 0);
        }
      }
    };

  const handleKeyDown = (index: number) => (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && index > 0) {
      const newValues = [...values];
      if (!event.currentTarget.value) {
        newValues[index - 1] = '';
        setValues(newValues);
        setTimeout(() => {
          inputRefs.current[index - 1]?.focus();
        }, 0);
      }
    }

    if (event.key === 'Backspace' && values.every(value => value === '')) {
      setTimeout(() => {
        inputRefs.current[0]?.focus();
      }, 0);
    }
  };


  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('text');
    const digits = pasteData.replace(/\D/g, '').slice(0, 6);

    if (digits.length > 0) {
      const newValues = [...values];

      digits.split('').forEach((digit, index) => {
        if (index < 6) {
          newValues[index] = digit;
          inputRefs.current[index]?.focus();
        }
      });

      setValues(newValues);
      onChange(newValues.join(''));
    }
  };


  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  return (
    <Container>
      <Label htmlFor=''>{label}</Label>
      <Content>
        {[...Array(6)].map((_, index) => (
          <Input
            key={index}
            type="text"
            error={error}
            maxLength={1}
            ref={el => (inputRefs.current[index] = el)}
            value={values[index]}
            onChange={handleInput(index)}
            onKeyDown={handleKeyDown(index)}
            onPaste={handlePaste}
            disabled={disabled}
            disable={disabled}
          />
        ))}
        {error && <HitError>Código inválido</HitError>}
      </Content>
    </Container>
  );
}
