interface StateProps {
  readonly setStepSwitch: (step: number) => void;
  readonly state: string;
  readonly setIsLoading: (loading: boolean) => void;
}

interface QualificationInfo {
  conta?: {
    nome?: string;
    cpf?: string;
    nomeSocial?: string;
    dataNascimento?: string;
    email?: string;
    celular?: string;
    optInComunicacao?: string;
  };
  estado?: string;
  idQualificacao?: string;
}

function getProcesso(pathname: string): string {
  const processoMap: Record<string, string> = {
    '/recadastramento': 'recadastramento',
    '/nova-conta': 'nova-conta',
    '/edicao': 'edicao',
  };
  
  return processoMap[pathname] || 'autenticacao';
}

function getStepForProcesso(processo: string, estado: string | undefined): number {
  const processoToStep: Record<string, (estado: string | undefined) => number> = {
    'recadastramento': (estado) => {
      switch (estado) {
        case 'nova-qualificacao': return 1;
        case 'mfa-enviado': 
        case 'mfa-enviado-email-opt-in':
        case 'mfa-enviado-telefone-opt-in': return 2;
        case 'mfa-validado':
        case 'mfa-validado-email-opt-in':
        case 'mfa-validado-telefone-opt-in': return 3;
        case 'conta-qualificada': return 7;
        default: return 0;
      }
    },
    'nova-conta': (estado) => {
      switch (estado) {
        case 'nova-qualificacao': return 1;
        case 'mfa-enviado': 
        case 'mfa-enviado-email-opt-in':
        case 'mfa-enviado-telefone-opt-in': return 4;
        case 'mfa-validado': return 5;
        case 'mfa-validado-email-opt-in': return 2;
        case 'mfa-validado-telefone-opt-in': return 2;
        case 'conta-qualificada': return 7;
        default: return 0;
      }
    },
    'edicao': (estado) => {
      switch (estado) {
        case 'nova-qualificacao': return 1;
        case 'mfa-enviado': 
        case 'mfa-enviado-email-opt-in':
        case 'mfa-enviado-telefone-opt-in': return 3;
        case 'mfa-validado': return 4;
        case 'mfa-validado-email-opt-in': return 5;
        case 'mfa-validado-telefone-opt-in': return 5;
        case 'conta-qualificada': return 7;
        default: return 0;
      }
    },
    'autenticacao': (estado) => {
      switch (estado) {
        case 'nova-qualificacao': return 1;
        case 'mfa-enviado': 
        case 'mfa-enviado-email-opt-in':
        case 'mfa-enviado-telefone-opt-in': return 2;
        case 'mfa-validado': return 3;
        case 'mfa-validado-email-opt-in': return 4;
        case 'mfa-validado-telefone-opt-in': return 4;
        case 'conta-qualificada': return 7;
        default: return 0;
      }
    },
  };

  return processoToStep[processo] ? processoToStep[processo](estado) : 0;
}

export async function handleVerifyState(
  setStepSwitch: (step: number) => void,
  setIsLoading: (loading: boolean) => void,
  data: QualificationInfo
) {
  const pathname = window.location.pathname;
  const processo = getProcesso(pathname);

  localStorage.setItem('qualificacao', JSON.stringify(data));

  const step = getStepForProcesso(processo, data.estado);
  setStepSwitch(step);
  setIsLoading(false);
}
