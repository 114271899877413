import { BoxForm } from '../../BoxForm';
import { Alert, TextInput } from '@vr/ds-react';
import { useEffect, useState } from 'react';
import { Container, SubtitleFormEdit, TitleFormEdit } from './styles';
import { DatePicker } from '../../DatePicker/index';
import { InsertContacts } from './insertContacts';
import { InsertCpfAndDate } from './insertCpfandDate';
import { InsertName } from './insertName';
import apiService from '../../../services/api';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { handleGetUserInfo } from '../../../services/userService';

interface FormEditProps {
  readonly changeStep: (step: number) => void;
  readonly edit?: boolean;
  readonly conflictPhone?: boolean;
  readonly conflictEmail?: boolean;
  readonly conflictCpfprop?: boolean;
  readonly newAccount?: boolean;
  readonly changeLoading: (loading: boolean) => void;
}

export function FormEdit({ changeStep, edit, newAccount, conflictPhone, conflictEmail, conflictCpfprop, changeLoading }: FormEditProps) {
  const [invalidName, setInvalidName] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = useAuthToken();

  const qualificationInfo = newAccount && localStorage.getItem('forEdit') 
    ? JSON.parse(localStorage.getItem('forEdit') ?? '') 
    : newAccount 
    ? {
      conta: {
        nome: '',
        dataNascimento: '',
        email: '',
        celular: '',
        cpf: ''
      }
    } 
    : JSON.parse(localStorage.getItem('forEdit') ?? localStorage.getItem('qualificacao') ?? '{}');

  const [firstname, ...secondName] = qualificationInfo.conta?.nome?.split(" ") ?? '';

  const [conflictCpf, setConflictCpf] = useState(localStorage.getItem('conflictCpf') === 'true');
  const [name, setName] = useState(firstname ?? '');
  const [lastName, setLastName] = useState(secondName.join(' ') ?? '');
  const [cpfUser, setCpfUser] = useState(qualificationInfo.conta?.cpf ?? '');
  const [date, setDate] = useState(qualificationInfo.conta?.dataNascimento ?? '');
  const [email, setEmail] = useState(qualificationInfo.conta?.email ?? '');
  const [cell, setCell] = useState(qualificationInfo.conta?.celular ?? '');
  const [etapa, setEtapa] = useState(edit ? 4 : newAccount ? 1 : 3);
  const [errorCpf, setErrorCpf] = useState(false);
  const [isBlocked, setIsBlocked] = useState(true);
  const [switchForm, setSwitchForm] = useState(1);
  const [invalidDate, setInvalidDate] = useState(false);

  useEffect(() => {
    switch (true) {
      case conflictEmail || conflictPhone:
        setSwitchForm(3);
        break;
      case conflictCpfprop:
        setSwitchForm(2);
        break;
      default:
        setSwitchForm(1);
        break;
    }
  }, [conflictEmail, conflictPhone, conflictCpfprop]);

  async function postQualification(cpf: string): Promise<void> {
    changeLoading(true);

    try {
      const res = await apiService.post('/acesso-vr/v1/qualificacoes', {
        aplicacaoOrigem: {
          idAplicacao: urlParams.get('client_id'),
          urlRedirecionamento: urlParams.get('redirect_uri')
        },
        conta: {
          cpf: cpf.replace(/\D/g, '')
        },
        processo: 'nova-conta'
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          client_id: process.env.REACT_APP_SENSEDIA_CLIENT_ID
        }
      });

      console.log(res);

    } catch (err: any) {
      const inputString = err.response?.data?.mensagens?.[0]?.descricao || '';
      const regex = /ID: ([a-f0-9-]{36})/;
      const match = inputString.match(regex);

      if (match) {
        const id = match[1];
        if (token) {
          await handleGetUserInfo(urlParams, token, changeStep, changeLoading, id);
        }
      } else {
        changeLoading(false);
        changeStep(0);
      }
    } finally {
      changeLoading(false);
    }
  }

  function isValidAge(birthDateString: string) {
    if (birthDateString === '') {
      setInvalidDate(false);
    } else {
      setDate(birthDateString);
      const birthDate = new Date(birthDateString);
      const today = new Date();
      const age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();
      const dayDifference = today.getDate() - birthDate.getDate();
      if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        return age - 1 >= 16;
      }

      if (age >= 16) {
        setInvalidDate(false);
      } else {
        setInvalidDate(true);
      }
    }
  }

  function formatDateToISO(date: string) {
    const d = new Date(date);
    const year = d.getUTCFullYear();
    const month = String(d.getUTCMonth() + 1).padStart(2, '0');
    const day = String(d.getUTCDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  function handleSubmit() {
    const forEdit = {
      conta: {
        nome: name + ' ' + lastName,
        cpf: cpfUser,
        dataNascimento: formatDateToISO(date),
        email: email,
        celular: cell,
      }
    }
    try {
      localStorage.setItem('forEdit', JSON.stringify(forEdit));
      if (edit) {
        changeStep(5);
      } else if (newAccount) {
        changeStep(2);
      } else {
        changeStep(4);
      }
    } catch (error) {
      console.error('Error saving data:', error);
    }
  }

  function handleNextForm(step: number) {
    if (step === 2 && newAccount) {
      postQualification(cpfUser);
    } else if (step > 2) {
      handleSubmit();
    } else {
      setSwitchForm(switchForm + 1);
      setEtapa(etapa + 1);
    }
  }

  function handleCkeckIsValidScreen(step: number) {
    switch (step) {
      case 1:
        setIsBlocked(!(name !== "" && lastName !== ""));
        break;
      case 2:
        setIsBlocked(!(cpfUser !== '' && errorCpf === false && date !== '' && invalidDate === false));
        break;
      case 3:
        setIsBlocked(!(email !== '' && invalidEmail === false && cell !== ''));
        break;
      default:
        setIsBlocked(true);
        break;
    }
  }

  useEffect(() => {
    isValidAge(date);
    handleCkeckIsValidScreen(switchForm);
  }, [name, lastName, switchForm, cpfUser, date, email, cell])

  return (
    <BoxForm
      buttonRight="Continuar"
      steps={edit ? 8 : 7}
      completedSteps={etapa}
      buttonRightDesabled={isBlocked}
      buttonRightClick={() => handleNextForm(switchForm)}
    >
      {qualificationInfo.conta && (
        <Container>
          {conflictCpf && <Alert status='error' bodyText='CPF informado já está em uso' />}
          {switchForm === 1 && (
            <InsertName
              invalidName={invalidName}
              lastName={lastName}
              name={name}
              setLastName={(e) => setLastName(e)}
              setName={(e) => setName(e)}
              newAccount={newAccount}
            />
          )}

          {switchForm === 2 && (
            <InsertCpfAndDate
              newAccount={newAccount}
              conflictCpf={conflictCpf}
              conflictCpfprop={conflictCpfprop}
              errorCpf={errorCpf}
              setErrorCpf={setErrorCpf}
              date={date}
              setDate={setDate}
              invalidDate={invalidDate}
              cpfUser={cpfUser}
              isValidAge={(e) => isValidAge(e)}
              setConflictCpf={(e) => setConflictCpf(e)}
              setCpfUser={(e) => setCpfUser(e)}
            />
          )}

          {switchForm === 3 && (
            <InsertContacts
              newAccount={newAccount}
              cell={cell}
              email={email}
              setCell={setCell}
              setEmail={setEmail}
              conflictPhone={conflictPhone}
              conflictEmail={conflictEmail}
              invalidEmail={invalidEmail}
              setInvalidEmail={setInvalidEmail}
            />
          )}
        </Container>
      )}
    </BoxForm>
  );
}
