import { TextInput } from "@vr/ds-react";
import { SubtitleFormEdit, TitleFormEdit } from "./styles";

interface InsertContactsProps {
    newAccount?: boolean;
    cell?: string;
    email?: string;
    conflictPhone?: boolean;
    conflictEmail?: boolean;
    invalidEmail?: boolean;
    setInvalidEmail: (value: boolean) => void;
    setCell: (value: string) => void;
    setEmail: (value: string) => void;
}



export function InsertContacts({ newAccount, cell, email, conflictPhone, conflictEmail, invalidEmail, setInvalidEmail, setCell, setEmail }: InsertContactsProps) {

    function handleVerifyEmail(email: string) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(email)) {
            setInvalidEmail(false);
        } else {
            setInvalidEmail(true);
        }
    }

    function handleMaskPhone(value: string) {
        value = value.replace(/\D/g, '');
        value = value.substring(0, 11);
        value = value.replace(/^(\d{2})(\d)/g, '($1) $2');
        value = value.replace(/(\d{5})(\d)/, '$1-$2');
        setCell(value);
    }
    

    function handleCheckChangeEmail(newEmail: string) {
        if (newAccount) {
            return
        } else {
            const emailQualificacao = JSON.parse(localStorage.getItem('qualificacao') ?? '{}').conta.email;
            if (emailQualificacao !== newEmail) {
                localStorage.setItem('mudouEmail', 'true');
            } else {
                localStorage.removeItem('mudouEmail');
            }
        }
    }
    function handleCheckChangePhone(newPhone: string) {
        if (newAccount) {
            return
        } else {
            const phoneQualificacao = JSON.parse(localStorage.getItem('qualificacao') ?? '{}').conta.celular;
            if (phoneQualificacao !== newPhone) {
                localStorage.setItem('mudouTelefone', 'true');
            } else {
                localStorage.removeItem('mudouTelefone');
            }
        }
    }


    return (
        <>
            <TitleFormEdit>
                {newAccount ? 'Insira suas informações de contato' : 'Atualize também suas informações de contato'}
            </TitleFormEdit>
            <SubtitleFormEdit>
                Informe um e-mail que só você usa, pois o Acesso VR é individual e sempre fazemos verificações para garantir a segurança dos seus dados.
            </SubtitleFormEdit>
            <TextInput
                label="E-mail"
                hasError={invalidEmail || conflictEmail}
                hintText={invalidEmail ? 'O email informado é inválido' : conflictEmail ? 'Existe outro Acesso VR com este e-mail. Informe um que só você usa.' : ''}
                value={email}
                onChange={e => {
                    handleCheckChangeEmail(e.target.value)
                    setEmail(e.target.value);
                    handleVerifyEmail(e.target.value);
                }}
            />
            <TextInput
                label="Celular"
                value={cell}
                hasError={conflictPhone}
                hintText={conflictPhone ? 'Existe outro Acesso VR com este celular. Informe um que você usa.' : ''}
                onChange={e => {
                    handleCheckChangePhone(e.target.value)
                    handleMaskPhone(e.target.value)
                }}
            />
        </>
    )
}