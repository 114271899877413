import { Button, ProgressBar, Modal } from '@vr/ds-react';
import {
  Container,
  Head,
  Foot,
  Title,
  Content,
  LogoAndHelp,
  Logo,
} from './styles';
import { ReactNode, useState } from 'react';
import { IconType } from '@vr/ds-react/elements/icon/icons';
import logoImg from '../../assets/logo-facilita 2.png';
import { Faq } from '../FAQ';
import { NewModal } from '../NewModal';

interface BoxFormTypes {
  readonly children: ReactNode;
  readonly buttonRight?: string;
  readonly buttonLeft?: string;
  readonly buttonRightIcon?: IconType;
  readonly buttonLeftIcon?: IconType;
  readonly buttonRightClick?: () => void;
  readonly buttonLeftClick?: () => void;
  readonly heading?: string;
  readonly buttonLeftDesabled?: boolean;
  readonly buttonRightDesabled?: boolean;
  readonly steps?: number;
  readonly completedSteps?: number;
}

export function BoxForm({
  children,
  buttonRight,
  buttonLeft,
  buttonRightIcon,
  buttonRightClick,
  buttonLeftClick,
  buttonLeftDesabled,
  buttonRightDesabled,
  buttonLeftIcon,
  heading,
  steps,
  completedSteps,
}: BoxFormTypes) {
  const [modal, setModal] = useState(false);
  return (
    <Container>
      {modal &&
        <NewModal onRequestClose={() => setModal(false)}>
          <Faq />
        </NewModal>
      }
      <LogoAndHelp>
        <Logo src={logoImg} alt="" />
        <Button onClick={() => setModal(true)} colorScheme='secondary' rightIcon='question'>Ajuda</Button>
      </LogoAndHelp>
      <Content>
        <Head>
          <Title>{heading}</Title>
        </Head>
        {children}

        <Foot>
          {buttonLeft && (
            <Button
              variant="outlined"
              rightIcon={buttonLeftIcon}
              onClick={buttonLeftClick}
              disabled={buttonLeftDesabled}
            >
              {buttonLeft}
            </Button>
          )}
          {buttonRight && (
            <Button
              colorScheme="primaryDarkTextColor"
              rightIcon={buttonRightIcon}
              onClick={buttonRightClick}
              disabled={buttonRightDesabled}
            >
              {buttonRight}
            </Button>
          )}
        </Foot>
        {steps && completedSteps && (
          <ProgressBar completedSteps={completedSteps} steps={steps} />
        )}
      </Content>
    </Container>
  );
}
