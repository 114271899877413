import {useLocation} from 'react-router-dom';
import {CircularLoading} from '@vr/ds-react';
import {useEffect, useState} from 'react';
import {Container} from '../ReRegistration/styles';
import {TokenForm} from '../../components/FormsScreen/TokenForm';
import {ReadonlyForm} from '../../components/FormsScreen/ReadonlyForm';
import {FormEdit} from '../../components/FormsScreen/FormEdit';
import {Conclusion} from '../../components/FormsScreen/Conclusion';
import {SelectOptIn} from '../../components/FormsScreen/SelectOptIn';
import {NotFound} from '../../components/FormsScreen/NotFound';
import {useAuthToken} from '../../hooks/useAuthToken';
import {handleGetUserInfo} from '../../services/userService';
import {PasswordForm} from '../../components/FormsScreen/PasswordForm';
import {getUserInfo} from '../../services/getUserInfo';
import apiService from '../../services/api';


export default function PageEdit() {
  const location = useLocation();
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const [isLoading, setIsLoading] = useState(true);
  const [stepForm, setStepForm] = useState(99);
  const [start, setStart] = useState(true);
  const token = useAuthToken();
  const redirectUrl = urlParams.get('redirect_uri') ?? '';

  async function postQualification(cpf: string) {
    setIsLoading(true);
    apiService.post('/acesso-vr/v1/qualificacoes', {
      aplicacaoOrigem: {
        idAplicacao: urlParams.get('client_id'),
        urlRedirecionamento: urlParams.get('redirect_uri')
      },
      conta: {
        cpf: cpf
      },
      processo: 'edicao'
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        client_id: process.env.REACT_APP_SENSEDIA_CLIENT_ID
      },
    }).then((res) => {
      if (token) {
        handleGetUserInfo(urlParams, token, setStepForm, setIsLoading, res.data.idQualificacao);
      }
    }).catch((err) => {
      const inputString = err.response.data.mensagens[0].descricao;
      const regex = /ID: ([a-f0-9-]{36})/;
      const match = inputString.match(regex);

      if (match) {
        const id = match[1];
        if (token) {
          handleGetUserInfo(urlParams, token, setStepForm, setIsLoading, id);
        }
      } else {
        setIsLoading(false);
        setStepForm(0);
      }
    })
  }

  useEffect(() => {
    localStorage.setItem('redirecturl', urlParams.get('redirect_uri') ?? '');
    localStorage.setItem('clientid', urlParams.get('client_id') ?? '');

    if (token && start) {

      getUserInfo().then(userInfo => {
        if (!userInfo?.name) {
          setIsLoading(false);
          setStepForm(401);
          return;
        }
        postQualification(userInfo?.cpf ?? '');
        setStart(false);
      }).catch(error => {
        setIsLoading(false);
        setStepForm(401);
      });
    }
  }, [token]);

  return (
    <Container>
      <CircularLoading isLoading={isLoading} />
      {stepForm === 0 && <NotFound title="Não foi possível localizar seu cadastro" text='Entre em contato com a central de ajuda ou retorne a plataforma de origem.' textButton='Voltar' buttonRedirect={redirectUrl} />}
      {stepForm === 401 && <NotFound title="Oops! Parece que você não tem permissão para acessar esta página" text='Entre em contato com a central de ajuda ou retorne a plataforma de origem.' textButton='Voltar' buttonRedirect={redirectUrl} />}
      {stepForm === 1 && <ReadonlyForm changeStep={setStepForm} prev edit />}
      {stepForm === 2 && <SelectOptIn edit changeStep={setStepForm} changeLoading={setIsLoading} />}
      {stepForm === 3 && <TokenForm edit changeStep={setStepForm} changeLoading={setIsLoading} thisStep={stepForm} />}
      {stepForm === 4 && <FormEdit changeLoading={setIsLoading} edit changeStep={setStepForm} />}
      {stepForm === 5 && <ReadonlyForm edit changeStep={setStepForm} />}
      {stepForm === 6 && <PasswordForm edit changeStep={setStepForm} changeLoading={setIsLoading} />}
      {stepForm === 7 && <Conclusion />}
      {stepForm === 9 && <ReadonlyForm prev changeStep={setStepForm} />}
      {stepForm === 11 && <NotFound title="Já existe uma edição em andamento" text='Entre em contato com a central de ajuda ou retorne a plataforma de origem.' textButton='Voltar' buttonRedirect={redirectUrl} />}
      {stepForm === 22 && <FormEdit changeLoading={setIsLoading} changeStep={setStepForm} edit conflictEmail />}
      {stepForm === 33 && <FormEdit changeLoading={setIsLoading} changeStep={setStepForm} edit conflictPhone />}
      {stepForm === 44 && <FormEdit changeLoading={setIsLoading} changeStep={setStepForm} edit conflictCpfprop />}
    </Container>
  );
}
