import { useLocation } from 'react-router-dom';
import { CircularLoading } from '@vr/ds-react';
import { useEffect, useState } from 'react';
import { Container } from '../ReRegistration/styles';
import { TokenForm } from '../../components/FormsScreen/TokenForm';
import { ReadonlyForm } from '../../components/FormsScreen/ReadonlyForm';
import { FormEdit } from '../../components/FormsScreen/FormEdit';
import { Conclusion } from '../../components/FormsScreen/Conclusion';
import { SelectOptIn } from '../../components/FormsScreen/SelectOptIn';
import { NotFound } from '../../components/FormsScreen/NotFound';
import { useAuthToken } from '../../hooks/useAuthToken';
import { PasswordForm } from '../../components/FormsScreen/PasswordForm';
import {getUserInfo} from '../../services/getUserInfo';

export default function NewAccountPage() {
  const location = useLocation();
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const [isLoading, setIsLoading] = useState(false);
  const [stepForm, setStepForm] = useState(1);
  const token = useAuthToken();
  const redirecturl = urlParams.get('redirect_uri') ?? '';
  const [start, setStart] = useState(true);

  useEffect(() => {
    localStorage.setItem('redirecturl', urlParams.get('redirect_uri') ?? '');
    localStorage.setItem('clientid', urlParams.get('client_id') ?? '');
    if (token && start) {
      setStart(false);
      getUserInfo().then(userInfo => {
        if(userInfo?.name) {
          setIsLoading(false);
          setStepForm(401);
          return;
        }
      }).catch(error => {
        setIsLoading(false);
        setStepForm(401);
      });
    }
  }, [token]);

  return (
    <Container>
      <CircularLoading isLoading={isLoading} />
      {stepForm === 0 && <NotFound title="Não foi possível localizar seu cadastro" text='Entre em contato com a central de ajuda ou retorne a plataforma de origem.' textButton='Voltar' buttonRedirect={redirecturl} />}
      {stepForm === 401 && <NotFound title="Oops! Parece que você não tem permissão para acessar esta página" text='Entre em contato com a central de ajuda ou retorne a plataforma de origem.' textButton='Voltar' buttonRedirect={redirecturl} />}
      {stepForm === 1 && <FormEdit newAccount changeStep={setStepForm} changeLoading={setIsLoading} />}
      {stepForm === 2 && <ReadonlyForm newAccount changeStep={setStepForm} />}
      {stepForm === 3 && <SelectOptIn newAccount changeStep={setStepForm} changeLoading={setIsLoading} />}
      {stepForm === 4 && <TokenForm newAccount changeStep={setStepForm} changeLoading={setIsLoading} thisStep={stepForm} />}
      {stepForm === 5 && <PasswordForm newAccount changeStep={setStepForm} changeLoading={setIsLoading} />}
      {stepForm === 7 && <Conclusion />}
    </Container>
  );
}
