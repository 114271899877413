import { TextInput } from "@vr/ds-react";
import { SubtitleFormEdit, TitleFormEdit } from "./styles";

interface InsertNameProps {    
    readonly newAccount?: boolean;
    invalidName: boolean;
    name: string;
    lastName: string;
    setName: (value: string) => void;
    setLastName: (value: string) => void;
}

export function InsertName( { newAccount, invalidName, name, lastName, setName, setLastName }: InsertNameProps ) {
    return (
        <>
            <TitleFormEdit>{newAccount ? "Quem irá usar este Acesso VR?" : "Quem usa este Acesso VR?"}</TitleFormEdit>
            <SubtitleFormEdit>
                {newAccount ? "" : "Se seus dados estão desatualizados, errados ou incompletos, esta é a hora de corrigir."}
            </SubtitleFormEdit>
            <TextInput
                label="Nome"
                hasError={invalidName}
                hintText={
                    invalidName ? 'O nome deve conter pelo menos duas palavras' : ''
                }
                value={name}
                onChange={e => {
                    setName(e.target.value);
                }}
            />
            <TextInput
                label="Sobrenome"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
            />
        </>
    );
}