import { BoxForm } from '../../BoxForm';
import { Alert, TextInput } from '@vr/ds-react';
import { useEffect, useState } from 'react';
import apiService from '../../../services/api';
import { Container, ValidateItem, ValidatorsBox } from './styles';
import { Terms } from '../Terms';
import { handleVerifyState } from '../../../services/verifyState';

interface PassProps {
  readonly changeStep: (step: number) => void;
  readonly changeLoading: (loading: boolean) => void;
  readonly edit?: boolean;
  readonly newAccount?: boolean;
}

export function PasswordForm({ changeStep, changeLoading, edit, newAccount }: PassProps) {
  const [disabledLeft, setDisabledLeft] = useState(true);
  const [errorState, setErrorState] = useState(false);
  const qualificationInfo = JSON.parse(localStorage.getItem('qualificacao') ?? '{}');
  const [pass, setPass] = useState(localStorage.getItem('senha') ?? '');
  const [error, setError] = useState(false);
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const [ip, setIp] = useState('');
  const [versionTerm, setVersionTerm] = useState('');
  const [accepted, setAccepted] = useState(false);
  const [confirmPass, setConfirmPass] = useState(localStorage.getItem('senha') ?? '');
  const [validation, setValidation] = useState({
    length: 0,
    uppercase: 0,
    specialChar: 0,
    sequential: 0,
    identical: 0
  });
  const [validationClasses, setValidationClasses] = useState({
    length: '',
    uppercase: '',
    specialChar: '',
    sequential: '',
    identical: ''
  });

  useEffect(() => {
    updateButtonState();
    updateValidationClasses();
  }, [pass, confirmPass]);

  function validatePassword(pass: string) {
    setValidation({
      length: pass.length >= 8 ? 1 : 2,
      uppercase: /[A-Z]/.test(pass) ? 1 : 2,
      specialChar: /[!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/~`]/.test(pass) ? 1 : 2,
      sequential: /(012|123|234|345|456|567|678|789|890)/.test(pass) ? 2 : 1,
      identical: /(\d)\1{2,}/.test(pass) ? 2 : 1,
    });
  }

  function updateValidationClasses() {
    setValidationClasses(prevState => {
      const newValidationClasses = { ...prevState };

      if (validation.length === 1) {
        newValidationClasses.length = 'success';
      } else if (validation.length === 2) {
        newValidationClasses.length = 'error';
      } else {
        newValidationClasses.length = '';
      }

      if (validation.uppercase === 1) {
        newValidationClasses.uppercase = 'success';
      } else if (validation.uppercase === 2) {
        newValidationClasses.uppercase = 'error';
      } else {
        newValidationClasses.uppercase = '';
      }

      if (validation.specialChar === 1) {
        newValidationClasses.specialChar = 'success';
      } else if (validation.specialChar === 2) {
        newValidationClasses.specialChar = 'error';
      } else {
        newValidationClasses.specialChar = '';
      }

      if (validation.sequential === 1) {
        newValidationClasses.sequential = 'success';
      } else if (validation.sequential === 2) {
        newValidationClasses.sequential = 'error';
      } else {
        newValidationClasses.sequential = '';
      }

      if (validation.identical === 1) {
        newValidationClasses.identical = 'success';
      } else if (validation.identical === 2) {
        newValidationClasses.identical = 'error';
      } else {
        newValidationClasses.identical = '';
      }

      return newValidationClasses;
    });
  }

  function updateButtonState() {
    setDisabledLeft(!(pass === confirmPass && pass.length >= 8 && accepted === true));
  }

  function formatDateToISO(date: string) {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  function handleSubmit() {
    changeLoading(true);
    localStorage.setItem('senha', pass);
    const evento = localStorage.getItem('mudouEmail') === 'true' ? 'mfa-enviado-email-opt-in' : localStorage.getItem('mudouTelefone') === 'true' ? 'mfa-enviado-telefone-opt-in' : 'conta-editada';

    const apiPayload = {
      evento: evento,
      aplicacaoOrigem: {
        idAplicacao: urlParams.get('client_id'),
        urlRedirecionamento: urlParams.get('redirect_uri')
      },

      conta: getAccountPayload(),
    };

    apiService.put(`/acesso-vr/v1/qualificacoes/${qualificationInfo.idQualificacao}`, apiPayload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        client_id: process.env.REACT_APP_SENSEDIA_CLIENT_ID
      },
    })
      .then((response) => {
        localStorage.setItem('isFinal', 'true');
        handleVerifyState(changeStep, changeLoading, response.data)
      }
      )
      .catch((err) => {
        changeLoading(false);
        if(err.response.data.mensagens[0].descricao == 'E-mail informado já esta em uso'){
          changeStep(22)
        } else if(err.response.data.mensagens[0].descricao == 'Celular informado já esta em uso'){
          changeStep(33)
        } else if (err.response.data.mensagens[0].descricao == 'CPF informado já está em uso'){
          changeStep(44)
        } else {
          setError(true);
          console.log(err.response.data.mensagens[0].descricao)
        }
      });
  }


  useEffect(() => {
    updateButtonState()
  }, [accepted, pass, confirmPass]);

  function getAccountPayload() {
    const preEdit = JSON.parse(localStorage.getItem('forEdit') ?? '{}');
    return {
      nome: preEdit.conta.nome,
      cpf: preEdit.conta.cpf.replace(/\D/g, ''),
      nomeSocial: preEdit.conta.nomeSocial,
      dataNascimento: formatDateToISO(preEdit.conta.dataNascimento),
      email: preEdit.conta.email,
      celular: preEdit.conta.celular,
      termosDeUso: versionTerm,
      termosIpUsuario: ip,
      senha: pass
    };
  }

  function renderValidationItem(label: string, validationKey: keyof typeof validationClasses) {
    return (
      <ValidateItem className={validationClasses[validationKey]}>
        &bull; {label}
      </ValidateItem>
    );
  }

  function verifyConfirm(conf: string) {
    if (pass !== conf) {
      setErrorState(true);
    } else {
      setErrorState(false);
    }
  }

  return (
    <BoxForm
      heading={newAccount ? "Agora, vamos criar sua senha." : "Agora, vamos atualizar sua senha."}
      buttonRight={newAccount ? "Continuar" : "Atualizar senha e continuar"}
      steps={edit ? 8 : 7}
      buttonRightDesabled={disabledLeft}
      completedSteps={edit ? 8 : 7}
      buttonRightClick={handleSubmit}
    >
      {error &&
        <Alert css={{ padding: '0px 15px' }} bodyText='Algo de errado aconteceu, contate o admnistrador do sistema.' status='error' />
      }
      <Container>
        <TextInput
          hiddenText={true}
          label="Qual senha você quer usar?"
          onChange={e => { setPass(e.target.value); validatePassword(e.target.value); }}
          value={pass}
          name='password'
          data-testid="text-input-password"
        />
        <ValidatorsBox>
          {renderValidationItem('Informe ao menos 8 caracteres', 'length')}
          {renderValidationItem('Use ao menos 1 letra maiúscula', 'uppercase')}
          {renderValidationItem('Use caractere especial (;.!?@#%)', 'specialChar')}
          {renderValidationItem('Não use números em sequência (12345678)', 'sequential')}
          {renderValidationItem('Não use números repetidos (22222222)', 'identical')}
        </ValidatorsBox>
        <TextInput
          data-testid="text-input-confirm-password"
          hiddenText={true}
          label="Repita a senha"
          onChange={e => { setConfirmPass(e.target.value); verifyConfirm(e.target.value) }}
          value={confirmPass}
          name='passwordConfirm'
          hasError={errorState}
          hintText={errorState ? 'Digite a mesma senha do campo anterior' : ' '}
        />
      </Container>
      <Terms changeAccepted={(check, ip, version) => { setAccepted(check); setIp(ip); setVersionTerm(version); }} />
    </BoxForm>
  );
}
