import apiService from '../../../services/api';
import { BoxForm } from '../../BoxForm';
import { InputToken } from '../../InputToken';
import { TextInfo } from '../../TextInfo';
import { useState } from 'react';
import { CollapseHelp } from './CollapseHelp';
import { Countdown } from '../../Countdown/index';
import { handleVerifyState } from '../../../services/verifyState';

interface TokenProps {
  readonly changeStep: (step: number) => void;
  readonly changeLoading: (loading: boolean) => void;
  readonly thisStep: number;
  readonly edit?: boolean;
  readonly newAccount?: boolean;
}

export function TokenForm({ changeStep, changeLoading, thisStep, edit, newAccount }: TokenProps) {
  const [invalid, setInvalid] = useState(false);
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const qualificationInfo = JSON.parse(localStorage.getItem('qualificacao') ?? '{}');
  const [code, setCode] = useState<number>(0);
  const estado = qualificationInfo.estado;

  function getEvento(estado: string): string {
    switch (estado) {
      case 'mfa-enviado-email-opt-in':
        return 'mfa-validado-email-opt-in';
      case 'mfa-enviado-telefone-opt-in':
        return 'mfa-validado-telefone-opt-in';
      default:
        return 'mfa-validado-normal';
    }
  }

  const evento = getEvento(estado);

  function formatDateToISO(date: string) {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  function fillZero(token: string) {
    return token.padStart(6, '0');
  }

  function handleVerifyCode() {
    changeLoading(true);
    apiService
      .put(
        `/acesso-vr/v1/qualificacoes/${qualificationInfo.idQualificacao}`,
        {
          evento: evento,
          aplicacaoOrigem: {
            idAplicacao: urlParams.get('client_id'),
            urlRedirecionamento: urlParams.get('redirect_uri'),
          },
          conta: {
            codigoMfa: fillZero(code.toString()),
            cpf: qualificationInfo ? qualificationInfo.conta?.cpf : '',
            editar: true,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            client_id: process.env.REACT_APP_SENSEDIA_CLIENT_ID,
          },
        },
      )
      .then((resp) => {
        switch (estado) {
          case 'mfa-enviado-email-opt-in':
            localStorage.removeItem('mudouEmail');
            break;
          case 'mfa-enviado-telefone-opt-in':
            localStorage.removeItem('mudouTelefone');
            break;
        }

        if (estado === 'mfa-enviado-email-opt-in' && localStorage.getItem('mudouTelefone') === 'true') {
          const apiPayload = {
            evento: 'mfa-enviado-telefone-opt-in',
            aplicacaoOrigem: {
              idAplicacao: urlParams.get('client_id'),
              urlRedirecionamento: urlParams.get('redirect_uri'),
            },
            conta: {
              nome: qualificationInfo.conta.nome,
              cpf: qualificationInfo.conta.cpf,
              dataNascimento: formatDateToISO(qualificationInfo.conta.dataNascimento),
              email: qualificationInfo.conta.email,
              celular: qualificationInfo.conta.celular,
              optInComunicacao: 'SMS',
            },
          };

          apiService
            .put(`/acesso-vr/v1/qualificacoes/${qualificationInfo.idQualificacao}`, apiPayload, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                client_id: process.env.REACT_APP_SENSEDIA_CLIENT_ID,
              },
            })
            .then((response) => {
              changeLoading(true);
              changeStep(0);
              handleVerifyState(changeStep, changeLoading, response.data);
            });
        } else {
          handleVerifyState(changeStep, changeLoading, resp.data);
        }
      })
      .catch(() => {
        changeLoading(false);
        setInvalid(true);
      });
  }

  function handleResendCode() {
    const evt = getEvento(estado).replace('validado', 'reenvio');
    apiService
      .put(
        `/acesso-vr/v1/qualificacoes/${qualificationInfo.idQualificacao}`,
        {
          evento: evt,
          aplicacaoOrigem: {
            idAplicacao: urlParams.get('client_id'),
            urlRedirecionamento: urlParams.get('redirect_uri'),
          },
          conta: {
            cpf: qualificationInfo ? qualificationInfo.conta?.cpf : '',
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            client_id: process.env.REACT_APP_SENSEDIA_CLIENT_ID,
          },
        },
      )
      .then(() => {
        console.log('reenviado');
      });
  }

  function handleGoBack() {
    changeStep(estado === 'mfa-enviado' ? thisStep - 1 : thisStep + 1);
  }

  return (
    <>
      {qualificationInfo.conta && (
        <BoxForm
          steps={edit ? 8 : 7}
          completedSteps={edit ? 3 : newAccount ? 6 : 2}
          heading={
            estado === 'mfa-enviado-telefone-opt-in'
              ? `Insira o código de 6 números que enviamos para ${qualificationInfo.conta.celular}`
              : estado === 'mfa-enviado-email-opt-in'
              ? `Insira o código de 6 números que enviamos para ${qualificationInfo.conta.email}`
              : estado === 'mfa-enviado' && qualificationInfo.conta.optInComunicacao === 'EMAIL'
              ? `Insira o código de 6 números que enviamos para ${qualificationInfo.conta.email}`
              : `Insira o código de 6 números que enviamos para ${qualificationInfo.conta.celular}`
          }
          buttonRight="Verificar"
          buttonLeft="Voltar"
          buttonRightClick={() => handleVerifyCode()}
          buttonLeftClick={() => handleGoBack()}
        >
          {['mfa-enviado-telefone-opt-in', 'mfa-enviado-email-opt-in'].includes(estado) ? (
            <TextInfo>
              Como você atualizou esta informação de contato, precisamos garantir que está correta e que você ainda tem acesso a ela.
              <br /> <br />
            </TextInfo>
          ) : (
            <TextInfo>
              {qualificationInfo.conta.optInComunicacao === 'EMAIL' && (
                <>
                  Busque pelo e-mail enviado por <strong>não-responda@acesso.vr.com.br.</strong>
                </>
              )}
              <br /> <br />
            </TextInfo>
          )}
          <InputToken
            resendFunction={handleResendCode}
            onChange={(e) => {
              setCode(parseInt(e));
              setInvalid(false);
            }}
            label="Informe o código de verificação"
            error={invalid}
          />
          <Countdown onResend={() => handleResendCode()} second={60} />
          <CollapseHelp />
        </BoxForm>
      )}
    </>
  );
}
