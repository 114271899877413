import { BoxForm } from '../../BoxForm';
import {
  Alert,
  Button,
  RadioButton
} from '@vr/ds-react';
import { useEffect, useState } from 'react';
import { RadioCombo, Container, Ask, TitleModal, TextModal, BoxModal, Separator } from './styles';
import apiService from '../../../services/api';
import { TextInfo } from '../../TextInfo/index';
import { handleGetUserInfo } from '../../../services/userService';
import { NewModal } from '../../NewModal';
import { handleVerifyState } from '../../../services/verifyState';

interface QualificationInfo {
  conta?: {
    nome?: string;
    cpf?: string;
    nomeSocial?: string;
    dataNascimento?: string;
    email?: string;
    celular?: string;
    foto?: string;
    optInComunicacao?: string;
  };
  estado?: string;
  idQualificacao?: string;
}

interface TokenProps {
  readonly changeStep: (step: number) => void;
  readonly changeLoading: (loading: boolean) => void;
  readonly edit?: boolean;
  readonly newAccount?: boolean;
}

export function SelectOptIn({ changeStep, changeLoading, edit, newAccount }: TokenProps) {
  
  const [isError, setIsError] = useState(false);
  const [modalHelp, setModalHelp] = useState(false);
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const [qualificationInfo, setQualificationInfo] = useState<QualificationInfo>(
    {},
  );
  const [optin, setOptin] = useState('EMAIL');

  async function handleConfirmOptin() {
    changeLoading(true);
    const data = {
      evento: 'envio-mfa-normal',
      aplicacaoOrigem: {
        idAplicacao: urlParams.get('client_id'),
        urlRedirecionamento: urlParams.get('redirect_uri')
      },

      conta: {
        cpf: qualificationInfo ? qualificationInfo.conta?.cpf : "",
        optInComunicacao: optin,
        editar: true
      },
    };
    await apiService.put(
      `/acesso-vr/v1/qualificacoes/${qualificationInfo.idQualificacao}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          client_id: process.env.REACT_APP_SENSEDIA_CLIENT_ID
        },
      },
    ).then((response) => {
      handleVerifyState(changeStep, changeLoading, response.data)
    }).catch(error => { 
      if (error.response?.data?.mensagens[0].codigo === -3) {
        localStorage.setItem('titularidade', 'true');
        changeStep(2)
      } else {
        changeLoading(false);
        setIsError(true)
      }
    });
  }

  useEffect(() => {
    const qualificacao = localStorage.getItem('qualificacao');
    if (qualificacao) {
      const parsedQualificacao: QualificationInfo = JSON.parse(qualificacao);
      setQualificationInfo(parsedQualificacao);
      setOptin(parsedQualificacao.conta?.optInComunicacao ?? 'EMAIL');
    } else {
      const token = localStorage.getItem('token') ?? '';

      handleGetUserInfo(urlParams, token, changeStep, changeLoading);
    }
  }, []);

  return (
    <>
      {qualificationInfo.conta && (
        <BoxForm
          heading={newAccount ? "Para concluir, precisamos verificar se este acesso é seu." : "Para começar, precisamos verificar se este acesso é seu."}
          buttonRight="Enviar código de verificação"
          buttonRightClick={() => handleConfirmOptin()}
          buttonLeftClick={() => {newAccount ? changeStep(1) : setModalHelp(true); localStorage.setItem('goBack', 'true');}}
          buttonLeft='Meu e-mail e celular estão errados'
          steps={edit ? 8 : 7} completedSteps={edit ? 2 : newAccount ? 5 : 1}
        >
          {modalHelp &&
            <NewModal onRequestClose={() => setModalHelp(false)}>
              <BoxModal>
                <TitleModal>
                  Entre em contato com a gente para atualizarmos suas informações
                </TitleModal>
                <TextModal>
                  Ligue para nossa central de relacionamento no telefone: <strong>4004-4938</strong> . Mas antes, <strong>separe todos documentos necessários.</strong>
                </TextModal>
                <Separator>
                  <TextModal>
                    Vamos precisar confirmar:
                  </TextModal>
                  <TextModal>
                    <strong>
                      Seus dados
                    </strong>
                  </TextModal>
                  <TextModal>
                    <strong>
                      Dados da sua empresa
                    </strong>
                  </TextModal>
                  <TextModal>
                    <strong>
                      CPF dos seus trabalhadores
                    </strong>
                  </TextModal>
                </Separator>
                <TextModal>
                  Se os dados corresponderem aos cadastrados, vamos criar um pedido para atualizar seu e-mail e telefone, o que <strong>pode levar até 3 dias úteis.</strong>
                </TextModal>
                <Button colorScheme='primaryDarkTextColor' onClick={() => setModalHelp(false)}>Fechar</Button>
              </BoxModal>
            </NewModal>
          }
          <TextInfo>
            Vamos enviar um <strong>código de verificação</strong> para ter certeza que os dados informados estão corretos e que realmente são seus.
          </TextInfo>
          {isError && <Alert css={{ padding: '0px 15px' }} status='error' bodyText='Aconteceu um erro, contate o admnistrador do sistema.' />}
          <Container>
            <Ask>Por onde quer receber o código de verificação?</Ask>
            <RadioCombo>
              {qualificationInfo.conta.email && (
                <RadioButton
                  checked={optin === 'EMAIL'}
                  value="EMAIL"
                  label={qualificationInfo.conta.email}
                  onChange={() => {
                    setOptin('EMAIL');
                  }}
                />
              )}
              {qualificationInfo.conta.celular && (
                <RadioButton
                  checked={optin === 'SMS'}
                  value="SMS"
                  label={qualificationInfo.conta.celular}
                  onChange={() => {
                    setOptin('SMS');
                  }}
                />
              )}
            </RadioCombo>
          </Container>
        </BoxForm>
      )}
    </>
  );
}
