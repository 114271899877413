import { BoxForm } from '../../BoxForm';
import joinhaImg from '../../../assets/iustração_jóia 1.svg';

import { Container, Title, Text, Image } from '../../../global';
import { useEffect } from 'react';

export function Conclusion() {
  const qualificationInfo = JSON.parse(
    localStorage.getItem('qualificacao') ?? '{}',
  );

  function handleRedirect() {
    localStorage.removeItem('redirecturl');
    localStorage.removeItem('pre-account');
    localStorage.removeItem('clientid');
    localStorage.removeItem('userid');
    localStorage.removeItem('qualificacao');
    localStorage.removeItem('token');
    localStorage.removeItem('token');
    window.location.href = qualificationInfo.aplicacaoOrigem?.urlRedirecionamento;
  }

  window.addEventListener('beforeunload', function () {
    localStorage.clear();
  });

  useEffect(() => {
    localStorage.removeItem('senha');
    localStorage.removeItem('mudouEmail');
    localStorage.removeItem('mudouTelefone');
  }, []);


  return (
    <BoxForm buttonRightClick={() => handleRedirect()} buttonRight={`Ir para tela de entrada da aplicação de origem`}>
      <Container>
        <Image src={joinhaImg} alt="" />
        <Title>
          Atualizamos o e-mail e a senha do seu Acesso VR
        </Title>
        <Text>
          Vá para tela de entrada da sua aplicação de origem e <strong>informe seus dados para entrar</strong>.
          <br /><br />
          Lembre-se de <strong>favoritar o Acesso VR</strong>, pois ele será o único meio de acessar os serviços que contratou.
        </Text>
      </Container>
    </BoxForm>
  );
}
