import apiService from './api';

function getProcesso(pathname: string): string {
  switch (pathname) {
    case '/recadastramento':
      return 'recadastramento';
    case '/nova-conta':
      return 'nova-conta';
    case '/edicao':
      return 'edicao';
    default:
      return 'autenticacao';
  }
}

function determineStepForState(processo: string, estado: string | undefined): number {
  switch (estado) {
    case undefined:
    case 'nova-qualificacao':
      return 1;
    case 'mfa-enviado':
    case 'mfa-enviado-email-opt-in':
    case 'mfa-enviado-telefone-opt-in':
      return processo === 'edicao' ? 3 : processo === 'nova-conta' ? 4 : 2;
    case 'mfa-validado':
    case 'mfa-validado-email-opt-in':
    case 'mfa-validado-telefone-opt-in':
      return processo === 'edicao' ? 4 : processo === 'nova-conta' ? 5 : 3;
    case 'conta-qualificada':
      return 7;
    default:
      return 0;
  }
}

async function fetchUserInfo(urlParams: URLSearchParams, token: string, id: string | undefined, processo: string) {
  const userId = urlParams.get('UUID');
  const url = `/acesso-vr/v1/qualificacoes${id && ['edicao', 'recadastramento'].includes(processo) ? `/${id}` : `/${userId}`}`;

  return apiService.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      client_id: process.env.REACT_APP_SENSEDIA_CLIENT_ID,
    },
  });
}

export async function handleGetUserInfo(
  urlParams: URLSearchParams,
  token: string,
  setStepSwitch: (step: number) => void,
  setIsLoading: (loading: boolean) => void,
  id?: string
) {
  const pathname = window.location.pathname;
  const processo = getProcesso(pathname);
  localStorage.removeItem('qualificacao');

  try {
    const res = await fetchUserInfo(urlParams, token, id, processo);

    if (res.data?.aplicacaoOrigem?.idAplicacao !== urlParams.get('client_id')) {
      setStepSwitch(11);
    } else if (res.data.estado) {
      localStorage.setItem('qualificacao', JSON.stringify(res.data));
      setStepSwitch(determineStepForState(processo, res.data.estado));
    } else {
      setStepSwitch(0);
    }
  } catch (err) {
    console.log(err);
    console.error('Error fetching user info:', err);
  } finally {
    setIsLoading(false);
  }
}
