import {useEffect, useState} from 'react';
import Environment from '../enviroment';
import apiService from "../services/api";

export function useAuthToken() {
  const [token, setToken] = useState<string | null>(null);

  const fetchSignature = async () => {
    try {
      const response = await fetch(`${Environment.apiAcessoVrAuthUtils}/jwt-utils/v1/jwt-assinado?clientId=${Environment.jwtClientId}`);
      const data = await response.json();
      return data.signedToken;
    } catch (error) {
      console.error('Error fetching token:', error);
      return null;
    }
  };

  const fetchToken = async () => {
    try {
      const signedToken = await fetchSignature();
      const result = await apiService.post('/autenticacao-vrid-sso/v1/oauth/token', {
          grantType: "client_credentials",
          clientId: `${Environment.jwtClientId}`,
          clientAssertionType: "urn:ietf:params:oauth:client-assertion-type:jwt-bearer",
          clientAssertion: `${signedToken}`,
        },
        {
          headers: {
            client_id: process.env.REACT_APP_SENSEDIA_CLIENT_ID,
            "Content-Type": "application/json",
          },
        });
      const newToken = result.data?.accessToken;
      localStorage.setItem('token', newToken);
      setToken(newToken);
    } catch (error) {
      return null;
    }
  }

  useEffect(() => {
    const initialize = async () => {
      await fetchToken();
    };

    initialize();

    const intervalId = setInterval(() => {
      fetchToken();
    }, 290000);

    return () => clearInterval(intervalId);
  }, []);

  return token;
}
