import { Route, Routes, useLocation } from 'react-router-dom';

import ReRegistration from '../../pages/ReRegistration';
import { NotFound } from '../../components/FormsScreen/NotFound';
import PageEdit from '../../pages/PageEdit';
import NewAccountPage from '../../pages/NewAccountPage';
import AuthPage from '../../pages/AuthPage';

export default function RouterSwitch() {
  const location = useLocation();
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const redirecturl = urlParams.get('redirect_uri') ?? '';

  const routes: Record<string, JSX.Element> = {
    '/*': (
      <NotFound
        title="Página não encontrada"
        text="A página que você tentou acessar não existe."
        textButton="Voltar"
        buttonRedirect={redirecturl}
      />
    ),
    '/recadastramento/*': <ReRegistration />,
    '/edicao/*': <PageEdit />,
    '/nova-conta/*': <NewAccountPage />,
    '/autenticacao/*': <AuthPage />,
  };

  return (
    <Routes>
      {Object.keys(routes).map(path => (
        <Route key={`route-${path}`} path={path} element={routes[path]} />
      ))}
    </Routes>
  );
}
