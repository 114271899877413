import apiService from "../services/api";


const queryString = location.search;

const urlParams = new URLSearchParams(queryString);

async function fetchUserInfo(token: string) {
  try {
    const result = await apiService.get('/autenticacao-vrid-sso/v1/oidc/user-info', {
      headers: {
        Authorization: `Bearer ${token}`,
        client_id: process.env.REACT_APP_SENSEDIA_CLIENT_ID
      },
    });
    const userInfo = result.data;
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    return userInfo;
  } catch (error) {
    console.log("Passou aqui")
    return null;
  }
}

export async function getUserInfo() {

  if (urlParams.get('token') == null) return null;

  return await fetchUserInfo(urlParams.get('token') ?? '');
}




